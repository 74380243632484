.conteudo2 {
    display: block;
    position: relative;
    text-align: center;
    overflow-y: scroll;
    background-color: black;
}

.cortina2 {
    position:fixed;
    text-align: center;
    /* justify-content: center; */
    top:50%;
    left:50%;
    transform: translate(-50%, -50%);
    background-color: black;
    

    /* overflow-y: scroll; */
}

.cortina-imagem2{
    width: 100vw;
    height: 100vh;
    background-color: black;
    pointer-events: none;

}

.conteudo-pagina2 {
    position: absolute;
    top:50%;
    left:50.6%;
    transform: translate(-50%, -50%);
    display:block;
    width: 53%;
    max-height: 100%;
    min-width: 300px;
    text-align: center;
    overflow-y:scroll;
    scroll-behavior: smooth;
    padding-right: 17px;
    box-sizing: content-box;
    font-family: Pixellari;

    /* border: 2px solid rgb(0, 8, 255) */
}


/* .conteudo-botoes-navigate{
    position: absolute;
    top:10%;
    left:10%;
    transform: translate(-50%, -50%);
    width:20%;
    cursor:pointer;
    border: 2px solid rgb(0, 8, 255)
} */

.conteudo-botao {
    position: absolute;
    top:10%;
    left:10%;
    transform: translate(-50%, -50%);
    width:15%;
    cursor:pointer;
}
.conteudo-botao-imagem{
    width:70%;
    min-width: 50px;
}


.conteudo-botao-2 {
    position: absolute;
    top:10%;
    right:-5%;
    transform: translate(-50%, -50%);
    width:15%;
    cursor:pointer;
    /* border: 2px solid rgb(255, 0, 0) */
}

.conteudo-botao-imagem-selector{
    width:70%;
    min-width: 50px;
}

.conteudo-pagina2::-webkit-scrollbar {
    display: none;
}

.conteudo-pagina-imagem2 {
    width:100%;
    cursor:pointer;
}

.conteudo-controle2 {
    display:inline-block;
    position: relative;
    margin: auto 0;
    text-align: center;
    align-items: center;
    justify-content: flex-end;
    border: 2px solid rgb(255, 0, 0)

}

.conteudo-controle-contador2{
    /* display:flex; */
    position: relative;
    display: inline-block;
    margin-top: 0.7%;
    /* padding-bottom: 0.5%; */
    width: 20%;
    /* min-width: 10%; */
    /* background-color: blue; */
    vertical-align: top;
    /* height: 100%; */
    /* pointer-events: none; */
    /* border: 5px solid blue */

}

 .conteudo-controle-contador2 img{
    width:100%;
    height: auto;
    display: inline-block;
    /* pointer-events: none; */
}

.change-page-column{
    display: grid;
    /* grid-template-columns: 30% 28% 30%; */
    grid-template-columns: 70px 220px 70px;
    margin: auto;
    justify-content: center;
    /* border: 2px solid red; */
    min-width: inherit;
    /* padding-left: 10px; */
}

.seta-esquerda{
    grid-column: 1/ span 1;
    /* border: 2px red solid; */
    width: 70px;
    /* padding-top: 30px; */
    cursor: pointer;
}
.botao-dropdown{
    grid-column: 2/ span 1;
    min-width: 230px;
    justify-content: center;
    /* border: 2px solid blue; */
    position:relative;
}
.botao-dropdown-imagem{
    width: 220px;
    height: 50px;
    position:absolute;
    top: 0px;
    left: 0px;
    z-index: -1;
    /* border: 2px solid rgb(247, 224, 14); */
}

.botao-dropdown-select{
    transform: translate(-0% , 15%);
    background-color: aqua;
    border: 0px solid #fff;
    background-color: transparent;
    font-size: 1.3em;
    width: 170px;
    height: 40px;
    cursor: pointer;
    font-family: Pixellari;


    /* border: 2px solid green; */
}
.seta-direita{
    grid-column: 3/ span 1;
    /* border: 2px red solid; */
    width: 70px;
    /* padding-top: 30px; */
    cursor: pointer;

}


.conteudo-controle-contador-posição{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 1.3em;
    pointer-events: none;
}

.conteudo-controle-contador-valor{
    position:relative;
    margin: 10px solid rgb(255, 0, 0);
}

.conteudo-controle-seta2 {
    Position: relative;
    width: 6%;
    /* display:inline-block; */
    /* margin: auto auto; */
    padding-left:10px;
    cursor:pointer;
    /* border: 2px solid rgb(255, 0, 0) */
}

.conteudo-controle-titulo {
    position:relative;
    display:flex;
    justify-content: center;
    align-items: center;
}

.conteudo-controle-nome-imagem {
    min-width: 300px;
    display:flex;
    justify-content: center;
    }

.conteudo-controle-nome-texto {
    position:absolute;
    top:50%;
    left:50%;
    transform: translate(-50% , -50%);
    font-size: 1.3em;
}


/* MOBILE  responsividade considerando a tela inicial de 360 x 640 (imaginária)*/
@media only screen and (min-width:100px) and (max-width: 500px ) {
    .cortina-imagem2{
        visibility: hidden;
    }
    /* Botão inicio e cap */
    .conteudo-botao {
        transform: translate(-40%, -60%);
        width: 20%;
        /* border: 2px solid rgb(255, 0, 0) */
    }
    .conteudo-botao-2 {
        transform: translate(-30%, -60%);
        width:20%;
        /* border: 2px solid rgb(255, 0, 0) */
    }
    /* Mudança de pagina */
    
    .change-page-column{
        display: grid;
        /* grid-template-columns: 30% 28% 30%; */
        grid-template-columns: 60px 190px 60px;
        margin: auto 0;
        /* border: 2px solid red; */
        min-width: inherit;
        padding-left: 0px;
        justify-content: center;
    }

    .change-page-column#upper{
        visibility: hidden;
        display: none;
    }
    .seta-esquerda{
        grid-column: 1/ span 1;
        /* border: 2px red solid; */
        width: 60px;
        /* padding-top: 30px; */
        cursor: pointer;

    }
    .botao-dropdown{
        grid-column: 2/ span 1;
        min-width: 180px;
        margin: 0 auto;
        /* border: 2px solid blue; */
    }
    .botao-dropdown-imagem{
        width: 180px;
        height: 50px;
        /* border: 2px solid rgb(247, 224, 14); */
    }
    .botao-dropdown-select{
        /* transform: translate(-0% , 110%); */
        font-size: 1.3em;
        padding-left: 20px;
        /* border: 2px solid green; */
    }
    .seta-direita{
        grid-column: 3/ span 1;
        /* border: 2px red solid; */
        width: 60px;
        /* padding-top: 30px; */
        cursor: pointer;
    
    }
    .conteudo-controle-titulo {
        position:relative;
        display:flex;
        justify-content: center;
        align-items: center;
        padding-left: 80px;
        padding-right: 80px;
    }
    .conteudo-controle-nome-imagem {
        min-width: 10px;
        display:flex;
        justify-content: center;
    }
   
    /* Imagem leitura */
    .conteudo-pagina2 {
        width: 95%;
        left:50%;
        max-height: 95%;
        min-width: 300px;
        /* border: 2px solid rgb(0, 8, 255); */
        transform: translate(-50%, -47%);
        padding-right: 0px;

    }
    .cortina2 {
                overflow-y: scroll;
    }
}

/* TABLET  responsividade considerando a tela inicial de 360 x 640 (imaginária)*/
@media only screen and (min-width:501px) and (max-width: 724px ) {
    
    /* Botão inicio e cap */
    .conteudo-botao {
        transform: translate(-50%, -60%);
        width: 20%;
        /* border: 2px solid rgb(255, 0, 0) */
    }
    .conteudo-botao-2 {
        transform: translate(-23%, -60%);
        width: 20%;
        /* border: 2px solid rgb(255, 0, 0) */
    }
    /* Mudança de pagina */
    
    .change-page-column{
        display: grid;
        /* grid-template-columns: 30% 28% 30%; */
        grid-template-columns: 60px 190px 60px;
        margin: auto 0;
        /* border: 2px solid red; */
        max-width: 724px;
        min-width: inherit;
        padding-left: 0px;
        justify-content: center;
    }

    .change-page-column#upper{
        visibility: hidden;
        display: none;
    }
    .seta-esquerda{
        grid-column: 1/ span 1;
        /* border: 2px red solid; */
        width: 60px;
        /* padding-top: 30px; */
        cursor: pointer;

    }
    .botao-dropdown{
        grid-column: 2/ span 1;
        min-width: 180px;
        margin: 0 auto;
        /* border: 2px solid blue; */
    }
    .botao-dropdown-imagem{
        width: 180px;
        height: 50px;
        /* border: 2px solid rgb(247, 224, 14); */
    }
    .botao-dropdown-select{
        /* transform: translate(-0% , 110%); */
        font-size: 1.3em;
        padding-left: 20px;
        /* border: 2px solid green; */
    }
    .seta-direita{
        grid-column: 3/ span 1;
        /* border: 2px red solid; */
        width: 60px;
        /* padding-top: 30px; */
        cursor: pointer;
    
    }
    .conteudo-controle-titulo {
        position:relative;
        display:flex;
        justify-content: center;
        align-items: center;
        padding-left: 80px;
        padding-right: 80px;
    }
    .conteudo-controle-nome-imagem {
        min-width: 10px;
        display:flex;
        justify-content: center;
    }
   
    /* Imagem leitura */
    .conteudo-pagina2 {
        width: 62%;
        left:50%;
        max-height: 95%;
        min-width: 300px;
        /* border: 2px solid rgb(0, 8, 255); */
        transform: translate(-50%, -47%);
        padding-right: 0px;

    }
    /* .cortina2 {
                overflow-y: scroll;
    } */
}
/* .select{
    
}
Select {
    background: url(../../Images/Botoes_Murago_CENTRAL-menor-PG-LEITURA.png);
    background-size: cover;
    width: 115%;
    min-height:20px;
    max-width: 100px;
    background-repeat: no-repeat;
    margin: 0px;
} */

/* @media only screen and (max-width: 600px) {
    .conteudo-controle-seta2 {
        width: 8%;
    }
    
    .conteudo-controle-contador2{
        width: 30%;
    }

    .conteudo-controle-contador-valor2{
        font-size: 1.0em;
    }
    .conteudo-controle-nome-imagem {
        min-width: 50px;
        }
    .conteudo-controle-nome-texto {
        font-size: 80%;
    }
} */