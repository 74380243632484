.conteudo1{
    height:100vh;
    width:100vw;
    overflow: hidden;
}

.conteudo-cortinas{
    width: 100%;
    height: 100%;
}



 /* TABLET */
 @media only screen and (max-width: 800px) {
    /* .conteudo-cortinas{
        visibility: hidden;
    } */
/*     
    .conteudo1{
        background-image: url(" ../../Images/Cortinas/Cortina-Selecao-TODA--sem-fundo.png");
        background-size:auto;
        back
        width: 300px;
        height: 300px; 
        overflow: hidden;
    }
    
    .conteudo-cortinas{
        width: 0%;
        height: 0%;
        visibility: hidden;
    } */
}