@import url('../../App.css'); 

.conteudo {
    display: block;
    position: relative;
    text-align: center;
    overflow-y: scroll;
    overflow-x: hidden;
    font-family: Pixellari;
}



.conteudo-superior {
    width:100vw;
    height:95vh;
    /* background-image: url("../../Images/MainPage/home_page_background_desktop.png"); */
    background-image: url("../../Images/MainPage/home_page_background_desktop_compress.png");

    background-size: 100vw 100vh , contain;
    /* border:1px solid red; */
}

.conteudo-superior-principal{
    position:absolute;
    top:50%;
    left:50%;
    transform: translate(-50% , -50%);
    margin:0 auto;
    display:block;
    width:100vw;
    /* border: 2px solid red; */
    }

.conteudo-superior-principal-logo {
    width:70%;
    display:flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
}
.conteudo-superior-principal-logo img {
    width: 70%;
    min-width: 300px;
    /* border:1px solid red; */

}

.conteudo-superior-principal-botoes {
    position: relative;
    display:inline-block;
    margin: 3% auto;
    width: 30%;
    min-width: 300px;
    max-width: 320px;

}
.botao-maior{
    display:flex;
    justify-content: left;
    margin:1%;
    width: 90%;
    min-width: 200px;
    font-family: Pixellari;
    cursor: pointer;
    /* border: 2px solid rgb(228, 247, 14); */
}
.botao-maior-imagem{
    width:110%;
    height: 50px;
    /* border: 2px solid rgb(247, 61, 14); */

}
.botao-maior-texto{
    position:absolute;
    top:15%;
    left:50%;
    transform: translate(-50% , -50%);
    font-size: 1.2em;
    min-width: 300px;
    /* border: 2px solid rgb(220, 247, 14); */
}

.botao-amarelo{
    position:absolute;
    top:14%;
    left:88%;
    transform: translate(-50% , -50%);
    width: 21%;
    min-width: 30px;
    cursor:pointer;
    /* border: 2px solid rgb(247, 61, 14); */

}
.botao-amarelo-imagem{
    width: 109%;

}
.botao-amarelo-conteudo{
    display: flex;
    position:absolute; 
    top:45%;
    left:38%;
    transform: translate(-50% , -50%);

}



.botao-arco-imagem{
    position:relative;
    padding-top: 15%;
    left:0%;
    width: 89%;
}

.botao-amarelo-conteudo-texto{
    position:relative;
    right:7%; 
    padding-top: 37%;
    font-size: 1.3em;
    text-decoration: none;
    color: black;

    
}


.botao-menor{
    margin:2% auto;
    width:90%;
    cursor:pointer;
    /* border: 2px solid rgb(247, 61, 14); */

}
.botao-menor-imagem{
    width:100%;
    min-width: 100px;
}
.botao-menor-texto{
    position:absolute;
    top: 43%;
    left:50%;
    transform: translate(-50% , -50%);
    font-size: 1.2em;
    min-width: 300px;
    /* border: 2px solid rgb(37, 247, 14); */

}

.botao-doacao{
    margin:2% auto;
    width:90%;
    cursor:pointer;
}
.botao-doacao-imagem{
    width:80%;
    min-width: 100px;
    /* border: 1px solid rgb(247, 61, 14); */
}
.botao-doacao-texto{
    position:absolute;
    top: 78%;
    left:50%;
    transform: translate(-50% , -50%);
    /* font-size: 1.5em;
    min-width: 300px; */
    font-size: 1.2em;
    min-width: 100px;
    /* border: 1px solid rgb(247, 61, 14); */
}
.botao-doacao a {
    text-decoration: none;
    color: black;
}

.seta-extras{
    position: absolute;
    top: 145%;
    left:50%;
    transform: translate(-50% , -50%);
    /* border: 1px solid rgb(130, 130, 130); */
}
.seta-extras-imagem{
    width: 76%;
}
.seta-extras-texto{
    position: absolute;
    top: 129%;
    left:50%;
    transform: translate(-50% , -50%);
    font-size: 1.2em;
    color:white;
    min-width: 300px;
    /* border: 1px solid rgb(134, 14, 247); */
}

/*MOBILE*/
@media only screen and (min-width:100px) and (max-width: 500px ) {
    .conteudo-superior {
        background-image: url("../../Images/MainPage/home_page_background_mobile.jpg");
        /* background-image: url("../../Images/MainPage/home_page_background_mobile_compress.jpg"); */

        background-size: cover;
        width: 500px;
        height: 700px; 
    }

    .conteudo-superior-principal-logo img {
        min-width: 130%;
    }
    .botao-maior-imagem{
        height: 50px;
    }
    .botao-maior-texto{
        left:48%;
        padding-top: 3%;
    }
    .botao-menor-texto{
        top: 47%;
        left:50%;
        /* border: 2px solid rgb(37, 247, 14); */
    
    }

    .botao-arco-imagem{
        visibility: hidden;
    }
    .botao-amarelo-conteudo{
        left:30%;
        right: 100%;
    }
    .botao-amarelo-conteudo-texto{
        right: auto;

        /* border: 2px solid red; */
    }
    .botao-amarelo{
        top:17%;
        left:88%;
        width: 23%;
    }

}
/*TABLET*/
@media only screen and (min-width:500px) and (max-width: 900px ) {
    .botao-maior-texto{
        left:48%;
        padding-top: 3%;
    }
    .botao-menor-texto{
        top: 47%;
        left:50%;
    }
    .botao-amarelo-conteudo{
        left:30%;
        right: 100%;
    }
    .botao-amarelo-conteudo-texto{
        right: auto;

    }
    .botao-amarelo{
        top:17%;
        left:88%;
        width: 23%;
    } 
}

/*DESKTOP */
@media only screen and (min-width:900px) and (max-width: 2000px ) {
    .botao-maior-texto{
        top:18%;
        left:50%;
    }
    .botao-amarelo{
        top:16%;
        left:88%;
        width: 23%;
    }
    .botao-menor-texto{
        top: 47%;
        left:50%;
    }
  
}
/* Parte inferior! */

/*
Se precisar mudar a imagem: 
1- Primeira mude a height em .conteudo_inferior
2- Em cada configuração de tela @media, mude o background position: ---  yyypx -> para o valor que caiba tudo (teste center)

*/
.conteudo-inferior {
    /* background-image: url("../../Images/MainPage/Extra/extra_background_2024.jpg"); */
    background-image: url("../../Images/MainPage/Extra/extra_background_2024-2.jpg");

    background-size:cover;
    width: 1920px;
    height: 1600px;
    /* height: 1300px;  */
}
.grid-container-rows {
    display: grid;     /*1  2   3    4  5   6   7 */
    grid-template-rows: 25% 10% 12% 19% 0% 20% 14%;
    width: 100%;
    height: 100%;
}
.follow-us-columns {
    display: grid;
    grid-template-columns: 23% 40% 30%;
    /* border: 2px solid rgb(247, 189, 14); */
}
.social-media-grid {
    display: grid;
    grid-template-columns: 28% 15% 13% 13%;
    grid-template-rows: 10% 90%;
    /* border: 2px solid rgb(14, 247, 14); */
}

.tiktok-shell {
    grid-row: 2/ span 1;
    padding-top: 250px;
    padding-bottom: 30px;
    padding-left: 130px;
    padding-right: 10px;
    /* border: 2px solid rgb(134, 14, 247); */

}
.tiktok-link {
    cursor:pointer;
    height: 100%;
    width: 100%;
    /* border: 2px solid rgb(14, 247, 30); */
}

.facebook-shell {
    grid-row: 2/ span 1;
    padding-top: 260px;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
    /* border: 2px solid rgb(200, 14, 247); */

}
.facebook-link {
    cursor:pointer;
    height: 100%;
    width: 100%;
    /* border: 2px solid rgb(14, 247, 30); */
}


.insta-shell {
    grid-row: 2/ span 1;
    padding-top: 260px;
    padding-left: 0px;
    padding-right: 12px;
    padding-bottom: 10px; 
    /* border: 2px solid rgb(14, 247, 30); */

}
.insta-link {
    cursor:pointer;
    height: 100%;
    width: 100%;
    /* border: 2px solid rgb(14, 247, 30); */
}

.twitter-shell {
    grid-row: 2/ span 1;
    padding-top: 250px;
    padding-bottom: 10px;
    padding-left: 6px;
    padding-right: 0px;
    /* border: 2px solid rgb(14, 247, 30); */

}
.twitter-link  {
    cursor:pointer;
    height: 100%;
    width: 100%;
    /* border: 2px solid rgb(14, 247, 30); */

}

.empty-space-1 {
    color: aqua;
    /* border: 2px solid rgb(247, 14, 14); */
    /* min-height: 130px; */
    /* width: 100%; */
}

/* .facebook-link:hover {
    box-shadow: 10px 10px 30px rgb(183, 3, 3)
}
.insta-link:hover {
    box-shadow: 10px 10px 30px rgb(183, 3, 3)
}
.twitter-link:hover {
    box-shadow: 10px 10px 30px rgb(183, 3, 3)
} */

.contribute-columns {
    display: grid;
    grid-template-columns: 30% 25% 30%;
    /* border: 2px solid rgb(247, 189, 14); */
}
.contribute-shell {
    /* border: 2px solid rgb(14, 247, 30); */
    grid-column: 2/ span 1;
    padding-top: 15px;
    padding-left: 90px;
    padding-right: 235px;
    padding-bottom: 20px;
}
.contribute-link {
    cursor: pointer;
    height: 100%;
    width: 100%;
    /* border: 2px solid rgb(14, 247, 30); */

}
.shopping-columns {
    display: grid;
    grid-template-columns: 10% 32% 58%;
    /* border: 2px solid rgb(247, 65, 14); */
}
.shopping-shell {
    /* border: 2px solid rgb(14, 247, 45); */
    grid-column: 2/ span 1;
    padding-top: 10px;
    padding-left: 250px;
    padding-right: 100px;
    padding-bottom: 20px;
}
.shopping-link {
    cursor: pointer;
    height: 100%;
    width: 100%;
    /* border: 2px solid rgb(14, 247, 30); */

}
.music-columns {
    display: grid;
    grid-template-columns: 20% 32% 58%;
    /* border: 2px solid rgb(14, 193, 247); */
}
.music-shell {
    /* border: 2px solid rgb(14, 247, 45); */
    grid-column: 2/ span 1;
    padding-top: 10px;
    padding-left: 250px;
    padding-right: 100px;
    padding-bottom: 20px;
}
.music-link {
    cursor: pointer;
    height: 100%;
    width: 100%;
    /* border: 2px solid rgb(247, 14, 235); */

}
.lol-columns {
    display: grid;
    grid-template-columns: 10% 32% 58%;
    /* border: 2px solid rgb(247, 65, 14); */
}
.lol-shell {
    /* border: 2px solid rgb(14, 189, 247); */
    grid-column: 2/ span 1;
    padding-top: 25px;
    padding-left: 270px;
    padding-right: 70px;
    padding-bottom: 30px;
}
.lol-link {
    cursor: pointer;
    height: 100%;
    width: 100%;
    /* border: 2px solid rgb(14, 232, 247); */

}
.grid-item-6 {
    border: 2px solid rgb(37, 247, 14);
    /* min-height: 130px; */
    /* width: 100%; */
}
 /* We going to refactor the media query to only get the heights because the width we nailed! https://gs.statcounter.com/screen-resolution-stats/mobile/brazil */
/* MOBILE SIZES */
@media only screen and (min-width:300px) and (max-width: 500px ) {
    .conteudo-inferior {
        /* background-image: url("../../Images/MainPage/Extra/mobile/extra_mobile3.jpg"); */
        background-image: url("../../Images/MainPage/Extra/mobile/extra_mobile-2024-2.jpg");
        width: 500px;
        height: 800px; 
        /* height: 610px;  */
    }
    .grid-container-rows {
        grid-template-rows: 22% 9% 12% 18% 0% 21% 16%;
    }
   
    .social-media-grid {
        display: grid;
        grid-template-columns: 26% 24% 25% 25%;
        grid-template-rows: 55% 45%;
    }
    
    .tiktok-shell {
        grid-row: 2/ span 1;
        padding-top: 0px;
        padding-bottom: 0px;
        padding-left: 0px;
        padding-right: 0px;
    }
    .tiktok-link {
        cursor:pointer;
        height: 100%;
        width: 100%;
    }

    .facebook-shell {
        grid-row: 2/ span 1;
        padding-top: 0px;
        padding-bottom: 0px;
        padding-left: 0px;
        padding-right: 0px;
    }
    .facebook-link {
        cursor:pointer;
        height: 100%;
        width: 100%;
    }
    .insta-shell {
        grid-row: 2/ span 1;
        padding-top: 0px;
        padding-left: 0px;
        padding-right: 0px;
        padding-bottom: 0px; 
    
    }
    .insta-link {
        cursor:pointer;
        height: 100%;
        width: 100%;
    
    }
    .twitter-shell {
        grid-row: 2/ span 1;
        padding-top: 0px;
        padding-bottom: 0px;
        padding-left: 0px;
        padding-right: 0px;
    }
    .twitter-link  {
        cursor:pointer;
        height: 100%;
        width: 100%;
    }
    
    .contribute-shell {
        grid-column: 2/ span 1;
        padding-top: 0px;
        padding-left: 0px;
        padding-right: 0px;
        padding-bottom: 0px;
    }
    .contribute-link {
        cursor: pointer;
        height: 100%;
        width: 100%;
    }
   
    .shopping-shell {
        grid-column: 2/ span 1;
        padding-top: 0px;
        padding-left: 0px;
        padding-right: 0px;
        padding-bottom: 0px;
    }
    .shopping-link {
        cursor: pointer;
        height: 100%;
        width: 100%;
    }
    .music-shell {
        grid-column: 2/ span 1;
        padding-top: 0px;
        padding-left: 0px;
        padding-right: 0px;
        padding-bottom: 0px;
    }
    .music-link {
        cursor: pointer;
        height: 100%;
        width: 100%;
    }
   
    .lol-shell {
        grid-column: 2/ span 1;
        padding-top: 0px;
        padding-left: 0px;
        padding-right: 0px;
        padding-bottom: 0px;
    }
    .lol-link {
        cursor: pointer;
        height: 100%;
        width: 100%;
    }
}

@media only screen and (min-width:300px) and (max-width: 350px ) {
   
    .conteudo-inferior {
        background-position: -90px 0px;
    }
    .follow-us-columns {
        display: grid;
        grid-template-columns: 9% 40% 30%;
    }
    .contribute-columns {
        display: grid;
        grid-template-columns: 20% 25% 30%;
    }
    .shopping-columns {
        display: grid;
        grid-template-columns: 4% 32% 58%;
    }
    .music-columns {
        display: grid;
        grid-template-columns: 4% 32% 58%;
    }
    .lol-columns {
        display: grid;
        grid-template-columns: 4% 32% 58%;
    }
}

@media only screen and (min-width:350px) and (max-width: 400px ) {
     
    .conteudo-inferior {
        background-position: -65px 0px;
    }
    .follow-us-columns {
        display: grid;
        grid-template-columns: 14% 40% 30%;
    }
    .contribute-columns {
        display: grid;
        grid-template-columns: 25% 25% 30%;
    }
    .shopping-columns {
        display: grid;
        grid-template-columns: 8% 32% 58%;
    }
    .music-columns {
        display: grid;
        grid-template-columns: 8% 32% 58%;
    }
    .lol-columns {
        display: grid;
        grid-template-columns: 8% 32% 58%;
    }
}
@media only screen and (min-width:400px) and (max-width: 450px ) {
        
    .conteudo-inferior {
        background-position: -38px 0px;
    }
    .follow-us-columns {
        display: grid;
        grid-template-columns: 20% 40% 30%;
    }
    .contribute-columns {
        display: grid;
        grid-template-columns: 31% 25% 30%;
    }
    .shopping-columns {
        display: grid;
        grid-template-columns: 13% 32% 58%;
    }
    .music-columns {
        display: grid;
        grid-template-columns: 13% 32% 58%;
    }
    .lol-columns {
        display: grid;
        grid-template-columns: 13% 32% 58%;
    }
}
@media only screen and (min-width:450px) and (max-width: 500px ) {
    .conteudo-inferior {
        background-position: -10px 0px;
    }
    .follow-us-columns {
        display: grid;
        grid-template-columns: 25% 40% 20%;
    }
    .contribute-columns {
        display: grid;
        grid-template-columns: 35% 25% 30%;
    }
    .shopping-columns {
        display: grid;
        grid-template-columns: 20% 32% 58%;
    }
    .music-columns {
        display: grid;
        grid-template-columns: 20% 32% 58%;
    }
    .lol-columns {
        display: grid;
        grid-template-columns: 20% 32% 58%;
    }
}

/* TABLET */
@media only screen and (min-width:500px) and (max-width: 900px ) {
    .conteudo-inferior {
        background-size:cover;
        width: 1400px;
        height: 1200px; 
        /* height: 950px;  */
    }
    .grid-container-rows {
        display: grid;     /*1  2   3    4  5   6   7 */
        grid-template-rows: 28% 4% 12% 20% 0% 20% 16%;
        width: 100%;
        height: 100%;
    }
    .social-media-grid {
        display: grid;
        grid-template-columns: 28% 15% 13% 13%;
        /* grid-template-columns: 20% 15% 13%; */
        grid-template-rows: 60% 25%;
    
    }
    .tiktok-shell {
        grid-row: 2/ span 1;
        padding-top: 0px;
        padding-bottom: 0px;
        padding-left: 0px;
        padding-right: 0px;
    
    }
    .tiktok-link {
        cursor:pointer;
        height: 100%;
        width: 100%;
    }
    .facebook-shell {
        grid-row: 2/ span 1;
        padding-top: 0px;
        padding-bottom: 0px;
        padding-left: 0px;
        padding-right: 0px;
    
    }
    .facebook-link {
        cursor:pointer;
        height: 100%;
        width: 100%;
    }
    .insta-shell {
        /* border: 2px solid rgb(14, 247, 30); */
        grid-row: 2/ span 1;
        padding-top: 0px;
        padding-left: 0px;
        padding-right: 0px;
        padding-bottom: 0px; 
    
    }
    .insta-link {
        cursor:pointer;
        height: 100%;
        width: 100%;
    }
    .twitter-shell {
        /* border: 2px solid rgb(14, 247, 30); */
        grid-row: 2/ span 1;
        padding-top: 0px;
        padding-bottom: 0px;
        padding-left: 0px;
        padding-right: 0px;
    }
    .twitter-link  {
        cursor:pointer;
        height: 100%;
        width: 100%;
    }
    
    .contribute-shell {
        /* border: 2px solid rgb(14, 247, 30); */
        grid-column: 2/ span 1;
        padding-top: 0px;
        padding-left: 0px;
        padding-right: 0px;
        padding-bottom: 0px;
    }
    .contribute-link {
        cursor: pointer;
        height: 100%;
        width: 100%;
    }
    
    .shopping-shell {
        /* border: 2px solid rgb(14, 247, 45); */
        grid-column: 2/ span 1;
        padding-top: 0px;
        padding-left: 0px;
        padding-right: 0px;
        padding-bottom: 0px;
    }
    .shopping-link {
        cursor: pointer;
        height: 100%;
        width: 100%;
    }

    .music-shell {
        /* border: 2px solid rgb(14, 247, 45); */
        grid-column: 2/ span 1;
        padding-top: 0px;
        padding-left: 0px;
        padding-right: 0px;
        padding-bottom: 0px;
    }
    .music-link {
        cursor: pointer;
        height: 100%;
        width: 100%;
    }
    
    .lol-shell {
        /* border: 2px solid rgb(14, 189, 247); */
        grid-column: 2/ span 1;
        padding-top: 0px;
        padding-left: 0px;
        padding-right: 0px;
        padding-bottom: 0px;
    }
    .lol-link {
        cursor: pointer;
        height: 100%;
        width: 100%;
    }
}


@media only screen and (min-width:500px) and (max-width: 599px ) {
    .conteudo-inferior {
        background-position: -450px 0px;
    }
    .follow-us-columns {
        display: grid;
        grid-template-columns: 1% 40% 30%;
    }
    .contribute-columns {
        display: grid;
        grid-template-columns: 14% 10% 30%;
    }
    .shopping-columns {
        display: grid;
        grid-template-columns: 3% 17% 58%;
    }
    .music-columns {
        display: grid;
        grid-template-columns: 3% 17% 58%;
    }
    .lol-columns {
        display: grid;
        grid-template-columns: 3% 17% 58%;
    }
}

@media only screen and (min-width:600px) and (max-width: 700px ) {
    .conteudo-inferior {
        background-position: -400px 0px;
    }
    .follow-us-columns {
        display: grid;
        grid-template-columns: 4.5% 40% 30%;
    }
    .contribute-columns {
        display: grid;
        grid-template-columns: 17% 10% 30%;
    }
    .shopping-columns {
        display: grid;
        grid-template-columns: 6% 17% 58%;
    }
    .music-columns {
        display: grid;
        grid-template-columns: 6% 17% 58%;
    }
    .lol-columns {
        display: grid;
        grid-template-columns: 6% 17% 58%;
    }
}
@media only screen and (min-width:700px) and (max-width: 800px ) {
    .conteudo-inferior {
        background-position: -315px 0px;
    }
    .follow-us-columns {
        display: grid;
        grid-template-columns: 10.5% 40% 30%;
    }
    .contribute-columns {
        display: grid;
        grid-template-columns: 24% 10% 30%;
    }
    .shopping-columns {
        display: grid;
        grid-template-columns: 13% 17% 58%;
    }
    .music-columns {
        display: grid;
        grid-template-columns: 13% 17% 58%;
    }
    .lol-columns {
        display: grid;
        grid-template-columns: 13% 17% 58%;
    }
}
@media only screen and (min-width:800px) and (max-width: 900px ) {
    .conteudo-inferior {
        background-position: -295px 0px;
    }
    .follow-us-columns {
        display: grid;
        grid-template-columns: 12% 40% 30%;
    }
    .contribute-columns {
        display: grid;
        grid-template-columns: 25% 10% 30%;
    }
    .shopping-columns {
        display: grid;
        grid-template-columns: 13% 17% 58%;
    }
    .music-columns {
        display: grid;
        grid-template-columns: 13% 17% 58%;
    }
    .lol-columns {
        display: grid;
        grid-template-columns: 13% 17% 58%;
    }
}


/* DESKTOP SIZES */
@media only screen and (min-width:900px) and (max-width: 1150px ) {
    .conteudo-inferior {
        background-position: -460px 0px;
    }
    .follow-us-columns {
        grid-template-columns: 8.9% 40% 30%;
    }
    .contribute-columns {
        grid-template-columns: 18% 25% 30%;
    }
    .shopping-columns {
        grid-template-columns: 0% 32% 58%;
    }
    .music-columns {
        grid-template-columns: 0% 32% 58%;
    }
    .lol-columns {
        grid-template-columns: 0% 32% 58%;
    }
}
@media only screen and (min-width:1150px) and (max-width: 1300px ) {
    .conteudo-inferior {
        background-position: -350px 0px;
    }
    .follow-us-columns {
        grid-template-columns: 15% 40% 30%;
    }
    .contribute-columns {
        grid-template-columns: 24% 25% 30%;
    }
    .shopping-columns {
        grid-template-columns: 5% 32% 58%;
    }
    .music-columns {
        grid-template-columns: 5% 32% 58%;
    }
    .lol-columns {
        grid-template-columns: 5% 32% 58%;
    }
}
@media only screen and (min-width:1300px) and (max-width: 1432px ) {
    .conteudo-inferior {
        background-position: -260px 0px;
    }
    .follow-us-columns {
        grid-template-columns: 19.5% 40% 30%;
    }
    .contribute-columns {
        grid-template-columns: 29% 25% 30%;
    }
    .shopping-columns {
        grid-template-columns: 10% 32% 58%;
    }
    .music-columns {
        grid-template-columns: 10% 32% 58%;
    }
    .lol-columns {
        grid-template-columns: 10% 32% 58%;
    }
}

@media only screen and (min-width:1433px) and (max-width: 1545px ) {
    .conteudo-inferior {
        background-position: -180px 0px;
        /* background-position: -180px +0px;  */
    }
    .follow-us-columns {
        grid-template-columns: 24% 40% 30%;
    }
    .contribute-columns {
        grid-template-columns: 33% 25% 30%;
    }
    .shopping-columns {
        grid-template-columns: 14% 32% 58%;
    }
    .music-columns {
        grid-template-columns: 14% 32% 58%;
    }
    .lol-columns {
        grid-template-columns: 14% 32% 58%;
    }
}

 @media only screen and (min-width:1546px) and (max-width: 1668px ) {
    .conteudo-inferior {
        background-position: -150px 0px;
    }
    .follow-us-columns {
        grid-template-columns: 25.5% 40% 30%;
    }
    .contribute-columns {
        grid-template-columns: 34% 25% 30%;
    }
    .shopping-columns {
        grid-template-columns: 15% 32% 58%;
    }
    .music-columns {
        grid-template-columns: 15% 32% 58%;
    }
    .lol-columns {
        grid-template-columns: 15.5% 32% 58%;
    }
}
@media only screen and (min-width:1669px) and (max-width: 1791px ) {
    .conteudo-inferior {
        background-position: -100px 0px;
    }
    .follow-us-columns {
        grid-template-columns: 28% 40% 30%;
    }
    .contribute-columns {
        grid-template-columns: 37% 25% 30%;
    }
    .shopping-columns {
        grid-template-columns: 18% 32% 58%;
    }
    .music-columns {
        grid-template-columns: 18% 32% 58%;
    }
    .lol-columns {
        grid-template-columns: 18% 32% 58%;
    }
}
@media only screen and (min-width: 1792px) and (max-width: 1900px ) {
    .conteudo-inferior {
        background-position: -60px 0px;
    }
    .follow-us-columns {
        grid-template-columns: 30% 40% 30%;
    }
    .contribute-columns {
        grid-template-columns: 39% 25% 30%;
    }
    .shopping-columns {
        grid-template-columns: 20% 32% 58%;
    }
    .music-columns {
        grid-template-columns: 20% 32% 58%;
    }
    .lol-columns {
        grid-template-columns: 20% 32% 58%;
    }
}
@media only screen and (min-width: 1901px) and (max-width: 2100px ) {
    .conteudo-inferior {
        background-position: -0px 0px;
    }
    .follow-us-columns {
        grid-template-columns: 33% 40% 30%;
    }
    .contribute-columns {
        grid-template-columns: 42% 25% 30%;
    }
    .shopping-columns {
        grid-template-columns: 23% 32% 58%;
    }
    .music-columns {
        grid-template-columns: 23% 32% 58%;
    }
    .lol-columns {
        grid-template-columns: 23% 32% 58%;
    }
}