@import url('../../App.css'); 

 
 
 /* CONTEUDO DO SELETOR DE CAPITULOS */


.conteudo {
    display: block;
    position: relative;
    background-color: black;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    font-family: Pixellari;
}

.botao-inicio{
    position: absolute;
    top:10%;
    left:10%;
    transform: translate(-50%, -50%);
    width: 8%;
    cursor:pointer;
    min-width: 70px;
}

.botao-inicio-img{
    width: 100%;
    min-width: 70px;
}

.conteudo-animacao {
    display:flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top:25%;
    left:50%;
    transform: translate(-50% , -50%);
}

.conteudo-animacao-seta-esquerda {
    width: 10%;
    min-width: 40px;
    cursor:pointer;
}
.conteudo-animacao-seta-direita {
    width: 10%;
    min-width: 40px;
    cursor:pointer;
}

.conteudo-animacao-imagem {
    width:65%;
    min-width: 200px;
    height: auto;
    /* border: 2px solid rgb(229, 255, 0); */
}
/* nesta estrutura, funciona para até 5 capitulos existentes num arco */
.conteudo-capitulos {
    display:flex;
    position:absolute;
    top: 75%;
    left: 50%;
    align-items: center;
    justify-content: center;
    transform: translate(-50% , -50%);
    height: 50%;
    width:50%;
    min-width:300px;
    color: aliceblue;
    /* border: 2px solid red; */
}



.conteudo-capitulos-moldura {
    max-height: 90%;
    width: 60%;
    /* border: 2px solid red; */

    
}

.conteudo-capitulos-titulo {
    position: absolute;
    /* centro da tela top=-5% */
    top: 0%;
    left:50%;
    padding-bottom: 5%;
    /* bottom: 0%; */
    transform: translate(-50%, -50%);
    font-size:2em;
    font-family: Komika;
    white-space: nowrap;
    /* border: 2px solid rgb(34, 255, 0) */
}



.conteudo-capitulos-texto {
    /* display: inline-block; */
    height: 70%;    
    width: 40%;   
    max-width: 38%;
    /* max-height: 65%;  */
    position: absolute;
    /* transform: translate(-50% , -50%);*/
    /* top:47%;  */
    /* left: 50%; */

    /* left:30%; <----------------------------- Para alinhar an esquerda!*/ 

    font-size: 1.3em;
    overflow-y: auto;
    overflow-x: hidden;

    /* border: 2px solid rgb(0, 255, 162); */

    /* width: fit-content;
    max-width: 80%;
    min-width: 60%; */
    
}
 

/* CONTEUDO DO MenuSeletor */   

/* SCROLL BAR */

/* width  of scroll bar*/
::-webkit-scrollbar {
    width: 3px;
  }

  /* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 5px;
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: red; 
    border-radius: 5px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #b30000; 
  }

.li{
    margin: 10px auto;
    text-align: start;
    white-space: normal;
    word-wrap: normal;
 
}

.lista {
    text-decoration: none;
    color: aliceblue;
    display: inline-block;

    justify-content: center;
    width: fit-content;
    vertical-align: middle;
    max-width: 70%;
    
    /* margin: 5px 3px; */
    /*BUttons for each chapter */
    /* padding: 10px; */
    /* border: 2px solid  rgb(245, 200, 65); */
    /* border-radius: 5px; */
    /* box-shadow: 3px 2px 0 2px rgba(255,192,0,1.000); */
    /* border: 1px solid green; */
    /* white-space: nowrap; */

}



 @media only screen and (max-width: 900px) {
    .conteudo-animacao-seta-direita {
        width: 10%;
        min-width: 50px;
        cursor:pointer;
    }
    .conteudo-capitulos-texto {
        font-size: 1.0em;
    }
 

 }


 /* MOBILE */
 @media only screen and (min-width: 300px) and (max-width: 380px){
    
    .conteudo-capitulos-texto {
        max-height: 42%; 
    }
    .conteudo-capitulos-titulo {
        padding-bottom: 0%;
        padding-top: 10%;
        font-size:1.5em;
    }
    .conteudo-capitulos {
        top: 65%;
    }
  
 }


 @media only screen and (min-width: 380px) and (max-width: 500px){
    .conteudo-capitulos-texto {
        max-height: 42%; 
    }
    .conteudo-capitulos-titulo {
        padding-bottom: 0%;
        padding-top: 10%;
        font-size:1.5em;
    }
    .conteudo-capitulos {
        top: 65%;
    }

 }

 /* TABLET */

 @media only screen and (min-width: 500px) and (max-width: 1000px){
    .botao-inicio{
        width: 8%;
        min-width: 110px;
    }
 }

 @media only screen and (min-width: 500px) and (max-width: 700px){
    .conteudo-capitulos-texto {
        max-height: 40%; 
        
    }
    
 }

 @media only screen and (min-width: 700px) and (max-width: 800px){
    .conteudo-capitulos-texto {
        max-height: 45%; 
        
    }

 }


 @media only screen and (min-width: 800px) and (max-width: 1100px){
    .conteudo-capitulos-texto {
        max-height: 40%; 
        
    }
    
 }




 /* DeskTOP */
 @media only screen and (min-width:1900px) and (max-width: 2000px) {
    .conteudo-capitulos-texto {
        font-size: 2.0em;
    }

    .lista {
        max-width: 90%;
        
        /* margin: 5px 3px; */
        /*BUttons for each chapter */
        /* padding: 10px; */
        /* border: 2px solid  rgb(245, 200, 65); */
        /* border-radius: 5px; */
        /* box-shadow: 3px 2px 0 2px rgba(255,192,0,1.000); */
        /* border: 1px solid green; */
        /* white-space: nowrap; */
    
    }
 }