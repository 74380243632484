.conteudo-pagina{
    display: block;
    position:absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50% , -50%);
    justify-content: center;
    font-family: Pixellari;
    color: white;
}
.imagem-error404{
    min-width: 300px;
    max-width: 500px;
}

.texto1 {
    text-align: center;
    font-size: 2.5em;   
}
.texto2 {
    text-align: center;
    font-size: 2em;   
}

@media only screen and (min-width:100px) and (max-width: 500px ) {

    .imagem-error404{
        min-width: 200px;
        max-width: 280px;
    }

    .texto1 {
        font-size: 1.5em;   
    }
    .texto2 {
        font-size: 1.5em;   
    }
}

@media only screen and (min-width:501px) and (max-width: 724px ) {

    .imagem-error404{
        min-width: 200px;
        max-width: 450px;
    }

    .texto1 {
        font-size: 1.5em;   
    }
    .texto2 {
        font-size: 1.5em;   
    }
}